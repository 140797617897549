import axios from 'axios';

import { PitchReviewModel } from '@/models/Reviews';
import { PitchTrackModel, PlaylistPitchesAPIModel } from '@/models/TrackInterfaces';

class PitchesAPI {
  static getPlaylistPitches = async ({
    playlistId,
    pageSize,
    status,
    contToken = '',
  }: {
    playlistId: string;
    pageSize: number;
    status: string;
    contToken?: string;
  }) =>
    await axios.get<PlaylistPitchesAPIModel>(
      `pitch/${playlistId}?pageSize=${pageSize}&status=${status}&continuationToken=${contToken}`
    );

  static getAllPitches = async ({
    accountId,
    pageSize,
    status,
    contToken = '',
    sortedBy = 'date',
    orderedBy = 'DESC',
  }: {
    accountId: string;
    pageSize: number;
    status: string;
    contToken?: string;
    sortedBy?: string;
    orderedBy?: string;
  }) =>
    await axios.get<PlaylistPitchesAPIModel>(
      `pitch/playlist/${accountId}?pageSize=${pageSize}&status=${status}&continuationToken=${contToken}&sortedBy=${sortedBy}&orderedBy=${orderedBy}`
    );

  static sendPitchReview = async (data: PitchReviewModel) =>
    await axios.put<PitchTrackModel>(`pitch/review-pitch`, data);

  static seedPitches = async (id: string) =>
    await axios.post<undefined>(
      `${id}/pitches?onlyDeletePending=true`,
      {},
      {
        baseURL: 'https://api.unhurd.co.uk/seed/curator/',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'unhurd-api-key': import.meta.env.VITE_PITCHES_SEED_API_KEY,
        },
      }
    );
}

export default PitchesAPI;
