import axios from 'axios';

import { ConnectedPlaylistStatsModel, PendingPitchesStatsModel, ReviewsOverTimeModel } from '@/models/Metrics';

class MetricsAPI {
  static getConnectedPlaylistStats = async ({ unhurdId, auth0Id }: { unhurdId: string; auth0Id?: string }) =>
    await axios.get<ConnectedPlaylistStatsModel>(`metrics/${unhurdId}/${auth0Id}/connected-playlists`);

  static getReviewsOverTime = async ({ unhurdId }: { unhurdId: string }) =>
    await axios.get<ReviewsOverTimeModel>(`metrics/${unhurdId}/pitch-responses`);

  static getPendingPitchesStats = async ({ unhurdId }: { unhurdId: string }) =>
    await axios.get<PendingPitchesStatsModel>(`metrics/${unhurdId}/pending-pitches`);
}

export default MetricsAPI;
