import axios from 'axios';

import { TransactionInterface } from '@/models/TransactionInterface';

class TransactionsAPI {
  static getTransactions = async ({
    id,
    pageSize,
    status,
    contToken = '',
  }: {
    id: string;
    pageSize: number;
    status: string;
    contToken?: string;
  }) =>
    await axios.get<TransactionInterface>(
      `transaction/${id}?pageSize=${pageSize}&status=${status}&continuationToken=${contToken}`
    );
}

export default TransactionsAPI;
