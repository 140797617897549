import axios from 'axios';

import { PayPalUpdateWalletSettingsModel } from '@/models/TransactionInterface';
import {
  SpotifyUserCheck,
  UpdateUserOnboardingModel,
  UpdateUserPasswordEmailModel,
  User,
} from '@/models/UserInterfaces';
import { CreateUserDetailsModel } from '@/models/UserInterfaces';
import { FormState } from '@/pages/SettingsPage';

class UserAPI {
  static getUserByID = async ({ id }: { id: string }) => await axios.get<User>(`account/${id}`);

  static checkSpotifyAccountExists = async ({ id }: { id: string }) =>
    await axios.get<SpotifyUserCheck>(`account/spotify/${id}/validate`);

  static createUser = async (data: CreateUserDetailsModel) => await axios.post<User>(`account`, data);

  static updateUserOnboarding = async ({
    auth0Id,
    unhurdId,
    data,
  }: {
    auth0Id: string;
    unhurdId: string;
    data: UpdateUserOnboardingModel;
  }) => await axios.put<User>(`account/${unhurdId}/${auth0Id}/onboarding/`, data);

  static updateUser = async ({ auth0Id, unhurdId, data }: { auth0Id: string; unhurdId: string; data: FormState }) =>
    await axios.put<User>(`account/${unhurdId}/${auth0Id}`, data);

  static updatePaypalDetails = async ({
    auth0Id,
    unhurdId,
    data,
  }: {
    auth0Id: string;
    unhurdId: string;
    data: PayPalUpdateWalletSettingsModel;
  }) => await axios.put<User>(`account/${unhurdId}/${auth0Id}/paypal`, data);

  static resendEmailVerification = async ({ auth0Id }: { auth0Id: string }) =>
    await axios.post<User>(`account/${auth0Id}/verification-email`);

  static sendPasswordChangeEmail = async (data: UpdateUserPasswordEmailModel) =>
    await axios.post<User>(`dbconnections/change_password`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      baseURL: import.meta.env.VITE_API_AUTH0_URL,
    });

  static deleteUser = async ({ auth0Id, unhurdId }: { auth0Id: string; unhurdId: string }) =>
    await axios.delete<User>(`account/${unhurdId}/${auth0Id}`, {});
}

export default UserAPI;
