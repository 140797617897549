import axios from 'axios';

import { PayPalUpdateWalletModel } from '@/models/TransactionInterface';

class AuthAPI {
  static getPayPalCredentials = async (data: { code: string }) =>
    await axios.post<PayPalUpdateWalletModel>(`auth/paypal/user-info`, data);

  static removeAllTokens = () => {
    localStorage.removeItem('unhurdID');
    localStorage.removeItem('userID');
    localStorage.removeItem('pp-code');
    localStorage.removeItem('sp-id');
    localStorage.removeItem('sp-expireTime');
    localStorage.removeItem('sp-accessToken');
    localStorage.removeItem('sp-refreshToken');
    localStorage.removeItem('auth_token');
  };
}

export default AuthAPI;
